<template>
  <div class="registry-sign-up-form">
    <div class="registry-sign-up-form-content d-block" v-if="user">
      <h5>My GlobalNDA</h5>
      <div class="company-image" v-if="user.enterpriseCompany.company_logo == null">
        <img src="../../../assets/images/company-logo.png" alt="" />
      </div>
      <div class="company-image" v-else>
        <img :src="user.enterpriseCompany.company_logo" alt="" />
      </div>
      <p v-if="user.enterpriseAgreement.status == 1">
        <span class="activeStatus">Active</span>
      </p>
      <p v-else-if="user.enterpriseAgreement.status == 2">
        <span class="terminateStatus">Terminated</span>
      </p>
      <p v-else>
        <span class="expireStatus">Expired</span>
      </p>
      <div class="company-info">
        <span class="heading">Company Name</span>
        <p class="description text-capitalize">
          {{ user.enterpriseCompany.company_name }}
          <img
            v-if="user.enterpriseAgreement.verify_status == 1"
            src="../../../assets/images/checkmark.png"
            alt=""
          />
        </p>
      </div>
      <div class="company-info">
        <span class="heading">Company Website</span>
        <p class="description">
          <a :href="getCleanURL(user.enterpriseCompany.company_website)" target="_blank">
            {{ user.enterpriseCompany.company_website.substring(0, 30) + "..." }}
          </a>
        </p>
      </div>
      <div class="company-info">
        <span class="heading">Company Address</span>
        <p class="description">
          {{ user.enterpriseCompany.address_1 }}
        </p>
      </div>
      <div class="line"></div>
      <div class="signed-expiration-date container-fluid pb-0">
        <div class="row no-gutters">
          <div class="col-md-6 mb-1 mb-md-0">
            <div class="signed-date">
              <span class="heading">Signed Date</span>
              <p class="description">
                {{ user.enterpriseAgreement.start_agreement_date }}
              </p>
            </div>
          </div>
          <div class="col-md-6">
            <div class="expiration-date" v-if="!user.enterpriseAgreement.terminate_agreement_date">
              <span class="heading">Expiration Date</span>
              <p class="description">
                {{ user.enterpriseAgreement.end_agreement_date }}
              </p>
            </div>
            <div class="expiration-date" v-else>
              <span class="heading">Termination Date</span>
              <p class="description">
                {{ user.enterpriseAgreement.terminate_agreement_date }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="line"></div> -->
      <div class="signed-expiration-date container-fluid">
        <div class="row no-gutters">
          <div class="col-md-6 mb-3 mb-md-0">
            <div class="signed-date">
              <span class="heading">Updated at</span>
              <p class="description">
                {{ detailsAgreement.createdAt | moment("YYYY-MM-DD") }} 
              </p>
            </div>
          </div>
          <div class="col-md-6">
            <div class="expiration-date" >
              <span class="heading">Last Login Date</span>
              <p class="description">
                {{ user.user.last_login_date }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="line"></div>
      <div class="signed-expiration-date container-fluid">
        <div class="row no-gutters">
          <div class="col-md-6 mb-3">
            <div class="signed-date">
              <span class="heading">Signed By</span>
              <p class="description text-capitalize">
                {{ user.user.first_name }} {{ user.user.last_name}}
              </p>
            </div>
          </div>
          <div class="col-md-6 mb-3">
            <div class="expiration-date">
              <span class="heading">Title</span>
              <p class="description text-capitalize">
                {{ user.user.title }}
              </p>
            </div>
          </div>
          <div class="col-md-6 mb-3 mb-md-0">
            <div class="signed-date">
              <span class="heading">Verification By</span>
              <p class="description text-capitalize">
                {{ user.enterpriseCompanyLegal.legal_contact_name }}
              </p>
            </div>
          </div>
          <div class="col-md-6">
            <div class="expiration-date">
              <span class="heading">Title</span>
              <p class="description text-capitalize">
                {{ user.enterpriseCompanyLegal.legal_contact_title }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <span v-if="user.enterpriseAgreement.status == 1">
      <div class="btns" v-if="user.user.role_id == 6 || user.user.role_id == 5">
        <button type="button" class="custom-btn2" @click="showAlert()">
          Terminate Contract
        </button> 
      </div>
      <div class="btns" v-else-if="user.user.role_id == 3">
        <button type="button" class="custom-btn2" @click="showAlert()">
          Terminate Contract
        </button>
        <button v-if="user.enterpriseAgreement.verify_status !=1 " type="button" class="custom-btn2" @click="updateVerifyStatus()">
          Confirm
        </button>
      </div>
      <div class="btns" v-else-if="user.user.role_id == 7 || user.user.role_id == 8">
        <button type="button" class="custom-btn2" @click="showAlert()">
          Terminate Contract
        </button>
        <button v-if="user.enterpriseAgreement.verify_status !=1 " type="button" class="custom-btn2" @click="updateVerifyStatus()">
          Confirm
        </button>
      </div>
    </span>
    <span v-else-if="user.enterpriseAgreement.status">
      <div class="btns" >
        <button type="button" class="custom-btn2" @click="signAgain()">
          Renew
        </button>
      </div>
    </span>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
export default {
  name: "AgreementDetailView",
  props:{
    detailsAgreement: Object
  },
  data() {
    return {
      agreement_id: "",
      details: {},
      fullPage: false
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  methods: {
    ...mapActions("auth", ["getUserData"]),
    signAgain(){
        this.$swal({
        title: "Do you want to renew your GlobalNDA?",
        text: "Please Verify.",
        icon: "info",
        confirmButtonText: "Yes",
        showCancelButton: true,
      }).then((result) => {
        if(result.isConfirmed) {
          // this.$root.$emit("openCardDetailModal", null, "Payment");
          var formData = new FormData();
              formData.append("agreement_id", this.user.enterpriseAgreement.id);
              formData.append("status", "1");
              formData.append("state", "3");
              axios
                .post(process.env.VUE_APP_API_URL + "enterprise/agreement/updateStatus", formData)
                .then((response) => {
                  if (response.data.statusCode === 200) {
                    this.getUserData();
                    this.$swal("Renewed!", "Your NDA has been updated.", "success");
                    this.$router.push({ name: "EnterpriseHome" });
                  }
                })
                .catch((error) => {
                  if (error.response.status === 500) {
                    alert(error.response.data.message);
                  }
                });
        }
      });
    },
    updateVerifyStatus(){
      this.$swal({
        title: "Confirm!",
        text: "Are you sure you want to verify your GlobalNDA?",
        icon: "info",
        confirmButtonText: "Confirm",
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          var formData = new FormData();
          formData.append("agreement_id", this.user.enterpriseAgreement.id);
          axios
            .post(process.env.VUE_APP_API_URL + "enterprise/agreement/updateVerifyStatus", formData)
            .then((response) => {
              if (response.data.statusCode === 200) {
                this.getUserData();
                this.$swal("Congratulations!", "Your GlobalNDA has been verified and a check mark has been added to your company profile.", "success");
                this.$router.push({ name: "EnterpriseSignedAgreements" });
              }
            })
            .catch((error) => {
              if (error.response.status === 500) {
                alert(error.response.data.message);
              }
            });
        }
      });
    },
    showAlert() {
      this.$swal({
        title: "Are you sure you want to terminate your GlobalNDA?",
        // text: "You will not receive a refund for the remaining year and must pay again to renew. Please see termination policy before confirming.",
        icon: "info",
        confirmButtonText: "Terminate",
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          var formData = new FormData();
          formData.append("agreement_id", this.user.enterpriseAgreement.id);
          formData.append("status", "2");
          formData.append("state", "2");
          axios
            .post(process.env.VUE_APP_API_URL + "enterprise/agreement/updateStatus", formData)
            .then((response) => {
              if (response.data.statusCode === 200) {
                this.getUserData();
                this.$swal("Terminated!", "Your GlobalNDA has been terminated.", "success");
                this.$router.push({ name: "EnterpriseHome"});
              }
            })
            .catch((error) => {
              if (error.response.status === 500) {
                alert(error.response.data.message);
              }
            });
        }
      });
    },
    getCleanURL(url) {
    // Remove search parameters from URL
    const cleanURL = url.split('?')[0];
    // Add http or https protocol if missing
    if (!cleanURL.startsWith('http://') && !cleanURL.startsWith('https://')) {
      return `http://${cleanURL}`;
    }
    return cleanURL;
  },
  },
  mounted() {
    this.$root.$on("agreeView", (data) => {
      this.details = data;
    });
  },
};
</script>

<style>
.activeStatus {
  color: #329c00;
  font-weight: 600;
  font-size: 18px;
  line-height: 22.68px;
}
.terminateStatus {
  color: #f5222d;
  font-weight: 600;
  font-size: 18px;
  line-height: 22.68px;
}
.expireStatus {
  color: #f5222d;
  font-weight: 600;
  font-size: 18px;
  line-height: 22.68px;
}
</style>
