<template>
  <div class="app-page">
    <Navbar />
    <div class="main-wrapper">
      <LeftMenu />
      <div class="registry-wrapper container-fluid">
        <div class="row">
          <div class="col-12 px-0">
            <div
              v-if="showloader == 0"
              class="lds-dual-ring-registry"
              id="loading" 
            ></div>
          </div>
          <div class="col-lg-6 pl-0">
            <AgreementDetailView v-if="showloader == 1" :detailsAgreement="preDATA" />
          </div>
          <div class="col-lg-6 mt-3 px-0">
            <NDAFormAgreement />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LeftMenu from "../../components/Enterprise/leftmenu/LeftMenu.vue";
import Navbar from "../../components/Enterprise/header/Navbar.vue";
import AgreementDetailView from "../../components/Enterprise/agreementdetail/AgreementDetailView.vue";
import NDAFormAgreement from "../../components/Enterprise/ndaform/NDAFormAgreement.vue";
import { mapGetters } from "vuex";
import axios from "axios";
export default {
  name: "EnterpriseAgreementDetail",

  data() {
    return {
      details: {},
      preDATA:{},
      showloader: 0,
      fullPage: false,
      loading: true,
    };
  },
  components: {
    Navbar,
    LeftMenu,
    AgreementDetailView,
    NDAFormAgreement,
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  methods:{
    getData(){
      var formData = new FormData();
      formData.append("user_id", this.preDATA.user_id);
      console.log(this.preDATA.user_id)
      axios
        .post(process.env.VUE_APP_API_URL + "enterprise/registry/EnterpriseViewer", formData)
        .then((response) => {
          if (response.data.statusCode === 200) {
              this.details = response.data.data;
              this.$root.$emit("ndaAgree", this.details);
              this.$root.$emit("agreeView", this.details);
              this.showloader = 1;
          }
        })
        .catch((error) => {
          if (error.response.status === 500) {
            alert(error.response.data.message);
          }
        });
    },

    getEntViewData(){
      var formData = new FormData();
      formData.append("agreement_id", this.preDATA.id);
      console.log("agreemnt id " + this.preDATA.id)
      axios
        .post(process.env.VUE_APP_API_URL + "enterprise/registry/enterpriseLegalView", formData)
        .then((response) => {
          if (response.data.statusCode === 200) {
              this.details = response.data.data;
              this.$root.$emit("ndaAgree", this.details);
              this.$root.$emit("agreeView", this.details);
              this.showloader = 1;
          }
        })
        .catch((error) => {
          if (error.response.status === 500) {
            alert(error.response.data.message);
          }
        });

    }
  },

  
  mounted() {
    this.$root.$on("dataForAgreement", (data) => {
      this.preDATA = data;
      // console.log(this.preDATA);
      this.getData();
    });
    this.$root.$on("templateView", (data) => {
      this.preDATA = data;
      this.showloader = 1
    });
    if(this.user.enterpriseAgreement != null ){
      this.preDATA = this.user.enterpriseAgreement
      this.getEntViewData();
    }
  },
};
</script>
<style scoped>
.lds-dual-ring-registry {
  position: absolute;
  z-index: 999;
  padding-top: 50% !important;
}
</style>
